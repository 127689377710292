/* Form Container */
.form-flex-container {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 20px;
}

/* Left Box */
.form-left-box {
  flex: 1;
  padding: 40px;
  background-color: var(--background-color);
  box-shadow: 8px 8px var(--box-bg-color);
  border-radius: 8px;
  /* margin-right: -90px; */
  z-index: 90;
}

/* Right Box */
.form-right-box {
  flex: 1;
  padding: 40px 120px;
  background-color: var(--background-color);
  box-shadow: 0px 4px 8px var(--box-bg-color);
  border-radius: 8px;
}

/* Box Titles */
.form-left-box h2,
.form-right-box h2 {
  font-size: 24px;
  color: var(--text-color);
  margin-bottom: 20px;
  font-weight: 600;
}

/* Paragraph Styles */
.form-left-box p {
  font-size: 18px;
  color: var(--text-color);
  margin-bottom: 20px;
}

/* Form Fields */
.form-right-box div {
  margin-bottom: 20px;
}

/* Label Styles */
.form-right-box label {
  display: block;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 6px;
  display: none;
}

/* Input Styles */
.form-right-box input {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--box-bg-color);
  border-radius: 5px;
  height: 20px;
}

.form-right-box textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--box-bg-color);
  border-radius: 5px;
  height: 50px;
}

/* Error Message Styles */
.form-right-box div > div {
  color: red;
  font-size: 14px;
  margin-top: 4px;
}

/* Button Styles */
.form-right-box button {
  background-color: var(--primary-color);
  color: var(--background-color);
  padding: 10px 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  height: 45px;
  width: 50%;
  font-size: 16px;
  font-weight: 500;
}

.form-right-box button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.form-right-box button:hover {
  opacity: 0.9;
}

.form-social-connect {
  margin-right: 10px;
}

/* Media Query for Mobile */
@media (max-width: 768px) {
  .form-flex-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 85vw;
  }

  .form-left-box,
  .form-right-box {
    flex: initial;
    margin-bottom: 20px;
  }

  .form-left-box {
    text-align: center;
    padding: 40px 25px;
  }

  .form-left-box h2,
  .form-right-box h2 {
    font-size: 20px;
    color: var(--text-color);
    margin-bottom: 20px;
    font-weight: 600;
  }

  .form-right-box {
    flex: 1;
    padding: 40px;
  }

  .form-right-box button {
    width: 100%;
    font-size: 16px;
  }

  .form-right-box input,
  .form-right-box textarea {
    width: 90%;
  }

  .form-left-box p {
    font-size: 16px;
  }
}

.sent-message {
  text-align: center;
  color: red;
  font-weight: 500;
  margin-bottom: 15px;
}
