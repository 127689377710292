.wordpress-projects-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px;
  position: relative;
}

.wp-project-list {
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
}

.wp-project-list li {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: var(--text-color);
  font-weight: 500;
  background-color: var(--background-color);
  box-shadow: 0px 4px 8px var(--box-bg-color);
  border-radius: 5px;
  padding: 15px;
  justify-content: center;
  text-decoration: none;
}

.wp-project-list a {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: var(--text-color);
  position: relative;
  text-decoration: none;
}

.wp-project-list a::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: var(--primary-color);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.wp-project-list a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* Addition for website image preview */
.preview-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.project-item {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: var(--text-color);
  font-weight: 500;
  background-color: var(--background-color);
  box-shadow: 0px 4px 8px var(--box-bg-color);
  border-radius: 5px;
  padding: 15px 20px;
  margin: 5px;
  justify-content: center;
  text-decoration: none;
  width: 250px;
}

.project-item a {
  text-decoration: none;  
  position: relative;
}

.project-item a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px; 
  bottom: 0;
  left: 0;
  background: linear-gradient(to right, #ff0000, #0c620c); /* Gradient from red to green */
  transform: scaleX(0); /* Initially set the underline to be invisible */
  transform-origin: bottom left;
  transition: transform 0.25s ease-out;
}

.project-item a:hover::after {
  transform: scaleX(1); /* Expand the underline on hover */
  transform-origin: bottom left;
}

.website-preview {
  position: absolute;
  top: -35%;
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 4px 8px var(--box-bg-color);
  z-index: 100;
  padding: 12.5px;
  background-color: #bb407d;
}

.project-item:hover .website-preview {
  display: flex;
  justify-content: center;
}

.website-preview img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

/* Media Query for Mobile Screens */
@media (max-width: 768px) {
  .wordpress-projects-container {
    padding: 20px;
    width: 80vw;
  }

  .wp-project-list {
    grid-template-columns: repeat(2, 1fr);
  }

  .wp-project-list li {
    font-size: 12px;
    padding: 8px;
    text-align: center;
    text-decoration: none;
  }

  .wp-project-list a::after {
    height: 2px;
  }
}
