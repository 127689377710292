/* Color Variables */
:root {
  --primary-color: #2c5f4e;
  --background-color: #fff;
  --text-color: #0a0430;
  --gray-color: #7f7f7f;
  --footer-bg: #f2f2f259;
  --box-bg-color: #d8d8d8;
  --shadow-color: rgba(127, 127, 127, 0.034);
  --project-boder: #d9e0efe7;
  --cursor-color: #2c5f4e1f;
}

/* Reset some default styles */
body,
h1,
h2,
h3,
p,
ul,
li {
  margin: 0;
  padding: 0;
}

/* Import Google Font */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

/* Set background color, font, and font-family */
body {
  background-color: var(--footer-bg);
  color: var(--text-color);
  font-family: "Poppins", Arial, sans-serif;
  scroll-behavior: smooth;
}

.cursor-effect {
  cursor: pointer;
}

.cursor-follower {
  position: absolute;
  width: 25px;
  height: 25px;
  background-color: var(--cursor-color);
  border-radius: 50%;
  pointer-events: none; /* Allow clicks to pass through */
  transform: translate(-50%, -50%);
  transition: all 0.2s ease-out;
  z-index: 999;
}

.cursor-effect.link-hovered .cursor-follower {
  width: 50px;
  height: 50px;
}

/* Header to display my logo on mobile only */
.just-header {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-color);
  box-shadow: 2px 3px 3px 3px var(--shadow-color);
  padding-bottom: 7.5px;
  width: 99vw;
  margin: 0 auto;
  /* border-radius: 50px; */
  border-radius: 0 0 50px 50px;
  display: none;
  z-index: 100; /* Ensure it's above other content */
}

.just-header img {
  max-height: 55px;
}

/* Navigation styles */
.navbar {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 2px 3px 3px 3px var(--shadow-color);
  padding: 5px 40px 5px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: var(--background-color);
  border-radius: 0 0 25px 25px;
  z-index: 100;
}

.logo img {
  max-height: 55px;
}

.menu {
  list-style: none;
  display: flex;
  align-items: center;
}

.menu li {
  margin-right: 30px;
}

.menu li:last-child {
  margin-right: 0;
}

.menu a {
  text-decoration: none;
  color: var(--text-color);
  font-weight: 600;
  transition: color 0.3s ease;
}

.menu a:hover {
  color: var(--primary-color);
}

/* Button styles */
.button {
  display: inline-block;
  padding: 12.5px 40px;
  background-color: var(--primary-color);
  color: var(--background-color);
  text-decoration: none;
  border-radius: 50px;
  border: 3px solid;
  border-color: var(--primary-color);
  transition: background-color 0.3s ease;
  font-weight: 600;
  scroll-behavior: smooth;
}

.button:hover {
  background-color: var(--gray-color);
  border-color: var(--gray-color);
}

/* Button 2 styles */
.button-2 {
  display: inline-block;
  padding: 12.5px 40px;
  font-weight: 600;
  background-color: var(--background-color);
  color: var(--primary-color);
  border: 3px solid;
  border-color: var(--primary-color);
  text-decoration: none;
  border-radius: 50px;
  transition: background-color 0.3s ease;
  scroll-behavior: smooth;
}

.button-2:hover {
  background-color: var(--primary-color);
  color: var(--background-color);
}

/* Footer styles */
.footer {
  position: sticky;
  bottom: 0;
  padding: 15px;
  background-color: var(--background-color);
  color: var(--text-color);
  text-align: center;
  z-index: 100; /* Ensure it's above other content */
}

/* Footer navigation icons */
.footer-icons {
  display: none; /* Hide the icons by default */
  justify-content: center;
  gap: 20px;
  font-size: 24px;
  margin-top: 10px;
}

.footer-icons a {
  color: var(--text-color);
  transition: color 0.3s ease;
}

.footer-icons a:hover {
  color: var(--primary-color);
}

/* Body styles */
.body-container {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  gap: 40px;
  padding: 30px;
}

.left-box {
  flex: 60%;
}

.right-box {
  flex: 40%;
  background: url("./my-image.png");
  background-repeat: no-repeat;
  background-position: right;
}

.name-text {
  font-size: 54px;
  color: var(--primary-color);
}

.buttons {
  margin-top: 20px;
  scroll-behavior: smooth;
}

.buttons a {
  margin-right: 10px;
  scroll-behavior: smooth;
}

.body_para {
  padding: 1.2rem 0rem;
  margin-top: 2rem;
  font-size: 20px;
  font-weight: 500;
}

a {
  color: var(--primary-color);
}

.socials {
  display: flex;
  margin-top: 1.5rem;
  padding: 20px 0 20px 5px;
  border-radius: 5px;
}

.socialImages img {
  width: 36px;
  height: 36px;
  transition: transform 0.3s; /* Animation */
  padding-right: 20px;
}

.socialImages img:hover {
  transform: scale(1.5);
}

/* Skills styles */
.skills-container {
  display: grid;
  max-width: 1200px;
  margin: 0 auto;
  gap: 25px;
  padding: 10px;
  grid-template-columns: repeat(6, 1fr); /* 6 columns per row */
  margin-bottom: 30px;
}

.skill-box {
  padding: 20px;
  background-color: var(--background-color);
  box-shadow: 2px 5px 5px 0px var(--box-bg-color);
  text-align: center;
  border-radius: 8px;
  transition: transform 0.3s; /* Animation */
}

.skill-box:hover {
  transform: scale(1.3);
}

.skill-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.skill-logo img {
  max-width: 50px;
}

.skill-box h3 {
  font-size: 18px;
  margin-bottom: 10px;
  color: var(--text-color);
  font-weight: 400;
}

.skill-box p {
  font-size: 14px;
  color: var(--gray-color);
}

/* Headers Styling */
.header-title-container {
  max-width: 1200px;
  margin: 0 auto;
}

.header-title {
  font-size: 20px;
  color: var(--primary-color);
  margin-bottom: 20px;
  font-weight: 500;
}

/* Portfolio styles */
.portfolio-container {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: -40px;
}

.project-box {
  padding: 20px 30px;
  background-color: var(--background-color);
  box-shadow: 0px 4px 8px var(--box-bg-color);
  text-align: center;
  border-radius: 8px;
}

h5 {
  margin-top: 15px;
  font-weight: 400;
}

.project-box h5 {
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: 500;
  color: var(--primary-color);
}

.project-box img {
  max-width: 100%;
  margin-bottom: 10px;
  border-radius: 5px;
  transition: transform 0.3s ease-in-out;
}

.project-image-border {
  border: 1px solid var(--project-boder);
}

.project-box img:hover {
  transform: scale(1.5);
}

.project-box p {
  font-size: 16px;
  color: var(--text-color);
  margin-bottom: 20px;
  font-weight: 400;
}

/* Portfolio link icons */
.link-icon {
  display: flex;
  padding: 10px 0 0 5px;
  border-radius: 5px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.link-icon img {
  width: 28px;
  height: 28px;
  transition: transform 0.3s; /* Animation */
  padding-right: 15px;
}

.link-icon img:hover {
  transform: scale(1.3);
}

/* Waving hand */
.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

@media (max-width: 768px) {
  /* Mobile views of relevant components needed on mobile */
  /* Footer on Mobile */
  .footer {
    position: sticky;
    bottom: 0;
    z-index: 100; /* Ensure it's above other content */
  }

  .footer-icons {
    display: flex;
  }

  .footer p {
    font-size: 0.9rem;
  }

  /* Portfolio on Mobile */
  .portfolio-container {
    width: 80vw;
  }

  /* Components title on Mobile */
  .header-title {
    font-size: 17px;
    text-align: center;
  }

  /* Projects on Mobile */
  .project-box p {
    font-size: 14px;
  }

  .project-box h5 {
    font-size: 16px;
    margin-bottom: 10px;
  }

  /* Projects link icons on Mobile */
  .link-icon img {
    width: 24px;
    height: 24px;
  }

  .link-icon {
    padding: 6px 0 0 5px;
  }

  .project-box {
    padding-bottom: 5px;
    padding-top: 8px;
  }

  /* Skills on Mobile */
  .skills-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    padding: 10px;
    width: 80vw;
  }

  .skill-box {
    flex: initial;
    padding: 15px;
    margin-bottom: 10px;
  }

  .skill-logo img {
    max-width: 40px;
  }

  .skill-box h3 {
    font-size: 14px;
    margin-bottom: 8px;
  }

  .skill-box p {
    font-size: 12px;
  }

  .navbar {
    width: 80vw;
  }

  .navbar {
    display: none;
  }
  .button {
    scroll-behavior: smooth;
  }
  .button-2 {
    scroll-behavior: smooth;
  }
  .just-header {
    display: flex;
    width: 100vw;
    border-radius: 0 0 20px 20px;
    background-color: var(--background-color);
    z-index: 100; /* Ensure it's above other content */
  }

  /* Body container on Mobile */
  .body-container {
    flex-direction: column;
    padding: 20px;
    width: 80vw;
  }

  .left-box {
    flex: initial;
    text-align: center;
  }

  .right-box {
    flex: initial;
    background: none;
    background-position: initial;
    text-align: center;
    margin-top: 20px;
    display: none;
  }

  .name-text {
    font-size: 30px;
    margin-top: 1px;
    color: var(--primary-color);
  }

  .buttons {
    margin-top: 15px;
    text-align: center;
    font-size: 14px;
    scroll-behavior: smooth;
  }

  .buttons a {
    margin-right: 10px;
    margin-bottom: 10px;
    scroll-behavior: smooth;
  }

  .body_para {
    padding: 1rem;
    font-size: 15px;
    margin-top: 0.5rem;
  }

  .socials {
    justify-content: center;
    margin-top: 1rem;
    padding: 10px 0;
  }

  .socialImages img {
    width: 24px;
    height: 24px;
    padding-right: 15px;
  }
}
